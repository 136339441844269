/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'file-easel-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M5 6.5a.5.5 0 01.5-.5h5a.5.5 0 01.5.5v2a.5.5 0 01-.5.5h-5a.5.5 0 01-.5-.5z"/><path pid="1" d="M12 0H4a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V2a2 2 0 00-2-2M8.5 5h2A1.5 1.5 0 0112 6.5v2a1.5 1.5 0 01-1.5 1.5h-.473l.447 1.342a.5.5 0 01-.948.316L8.973 10H8.5v1a.5.5 0 01-1 0v-1h-.473l-.553 1.658a.5.5 0 11-.948-.316L5.973 10H5.5A1.5 1.5 0 014 8.5v-2A1.5 1.5 0 015.5 5h2a.5.5 0 011 0"/>',
    },
});
